import { motion, useScroll, useTransform } from "framer-motion";
import React from "react";
import { FaGitAlt, FaReact } from "react-icons/fa";
import { IoChevronDownSharp, IoLogoFigma } from "react-icons/io5";
import {
    SiTypescript,
    SiRedux,
    SiMui,
    SiTailwindcss,
    SiPython,
    SiFastapi,
    SiMysql,
    SiGoogle,
    SiChartdotjs,
    SiTrello,
    SiGooglegemini,
} from "react-icons/si";
import { HiCurrencyEuro } from "react-icons/hi";
import { Link } from "react-router-dom";
import CaretTopRightIcon from "../../components/icons/TRCaret";

const TygaSmart = () => {
    const { scrollYProgress } = useScroll();
    const opacity = useTransform(scrollYProgress, [0, 0.05], [1, 0]);

    const technologies: { [key: string]: { [key: string]: JSX.Element } } = {
        Frontend: {
            TypeScript: <SiTypescript />,
            React: <FaReact />,
            Redux: <SiRedux />,
            MUI: <SiMui />,
            TailwindCSS: <SiTailwindcss />,
            ChartJS: <SiChartdotjs />,
            GoogleAPI: <SiGoogle />,
        },
        Backend: {
            Python: <SiPython />,
            FastAPI: <SiFastapi />,
            AOIMySQL: <SiMysql />,
            GoogleAPI: <SiGoogle />,
            CurrencyAPI: <HiCurrencyEuro />,
            Gemini: <SiGooglegemini />,
        },
        Database: {
            MySQL: <SiMysql />,
        },
        Tools: {
            Figma: <IoLogoFigma />,
            Git: <FaGitAlt />,
            Trello: <SiTrello />,
        },
    };

    return (
        <div className="w-full h-full min-h-screen flex items-center justify-center flex-col text-primary mb-24">
            <div className="w-full h-screen flex justify-center items-center">
                <div className="w-auto divide-y-[1px] divide-primary flex flex-col justify-center items-center">
                    <h1 className="text-4xl px-4 text-nowrap w-min">
                        TygaSmart
                    </h1>
                    <div className="flex flex-center items-center gap-12 w-full">
                        <p className="">SWE Intern</p>
                        <p className="">09 2024 - 12 2024</p>
                    </div>
                </div>
                <motion.p
                    style={{ opacity }}
                    className="flex gap-2 group justify-center items-center text-primary z-10 fixed bottom-1"
                >
                    Scroll Down <IoChevronDownSharp />
                </motion.p>
            </div>

            <div className="grid grid-cols-4">
                {Object.keys(technologies).map((area: string) => (
                    <div className="flex flex-col item-center justify-start">
                        <h2 className="text-center text-lg relative px-4 w-fit mx-auto">
                            {area}
                        </h2>
                        {Object.entries(technologies[area]).map(
                            ([name, icon]) => (
                                <div
                                    key={name}
                                    className="flex justify-center flex-col items-center py-2"
                                >
                                    <div className="text-3xl">{icon}</div>
                                    <p className="text-center text-sm">
                                        {name}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                ))}
            </div>

            <div
                className="md:w-1/3 mt-[10vh] flex justify-center items-center text-2xl mx-6 text-center"
                style={{ marginBottom: "calc(10vh + 2rem)" }}
            >
                <p>
                    As a Fall Intern, I'm working on the development of the ESG
                    Calculator for TygaSmart, an adverting tool that allows
                    businesses to see the impact their furniutre actions have on
                    the environment.
                </p>
            </div>

            <div className="flex justify-center items-center flex-col space-y-4 mb-24 w-full h-full">
                <iframe
                    title="ESG Calculator"
                    src="https://esg.tygasmart.com"
                    width="100%"
                    height="100%"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="sm:aspect-video aspect-[9/16] w-3/4 bg-white"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                />

                <Link
                    to={"https://esg.tygasmart.com"}
                    target="_blank"
                    className="mt-4 group relative px-2"
                >
                    TygaSmart's ESG Calculator
                    <CaretTopRightIcon className="group-hover:opacity-100 opacity-0 absolute top-0 right-0 w-2 h-2" />
                </Link>
            </div>
        </div>
    );
};

export default TygaSmart;
export const thumbnail = {
    img: "/assets/images/experience/tygasmart/thumbnail.png",
    company: "TygaSmart",
    title: "Software Engineering Intern",
    startDate: "09-2024",
    endDate: "12-2024",
    link: "/tygasmart",
};
