import React, { useEffect, useRef, useState } from "react";

const Education = () => {
    const imgRef = useRef<HTMLImageElement>(null);
    const pRef = useRef<HTMLParagraphElement>(null);
    const [imgHeight, setImgHeight] = useState<number | undefined>(undefined);

    useEffect(() => {
        const onResize = () => {
            if (pRef.current) {
                setImgHeight(pRef.current.clientHeight);
            }
        };

        window.addEventListener("resize", onResize);

        if (pRef.current) {
            setImgHeight(pRef.current.clientHeight);
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return (
        <div className="text-center">
            <p>BS CS - 3.9 GPA</p>
            <div className="flex justify-center items-center h-min text-left">
                <img
                    ref={imgRef}
                    src="/assets/images/university/usfca.png"
                    alt="USFCA"
                    className="flex-shrink-0 object-contain"
                    style={{ height: imgHeight }}
                />
                <p
                    ref={pRef}
                    className="flex items-center w-fit leading-5 py-4 font-sans uppercase text-2xl"
                >
                    University of
                    <br />
                    San Francisco
                </p>
            </div>
            <p className="italic">08 2023 - 05 2025</p>
        </div>
    );
};

export default Education;
